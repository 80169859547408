/*----CALLS LIST----*/
#project-info-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 70%;
  background: linear-gradient(
    to bottom left,
    rgba(0, 4, 79, 0.7),
    rgba(0, 0, 0, 0.7)
  );
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  user-select: none;
  overflow: hidden;
}

#project-info a {
  color: #dc7c06;
}

#project-info a:visited {
  color: #00b70f;
}

#project-info {
  position: fixed;
  padding: 20px;
  z-index: 5;
  color: white;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  user-select: none;
  touch-action: none;
}

#project-info h2 {
  font-size: 1rem;
  font-style: bold;
  line-height: 0.5;
}
#project-info p {
  font-size: 0.95rem;
  line-height: 1.1;
}

@media screen and (max-width: 400px) {
  #project-info h2 {
    font-size: 0.9rem;
    font-style: bold;
    line-height: 0.5;
  }

  #project-info p {
    font-size: 0.75rem;
    line-height: 1;
  }
}

@media screen and (max-width: 300px) {
  #project-info h2 {
    font-size: 0.7rem;
    line-height: 1;
  }

  #project-info p {
    font-size: 0.68rem;
    line-height: 1;
  }
}

@media screen and (min-width: 768px) and (min-height: 600px) {
  #project-info p {
    font-size: 1.3rem;
    padding-left: 20px;
  }
  #project-info h2 {
    padding-left: 20px;
    font-size: 2rem;
    font-style: bold;
  }
}
/*----LATEST INCIDENTS LIST----*/
#call-list-container {
  background: linear-gradient(
    to bottom left,
    rgba(1, 8, 147, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  animation: flicker 1s, fade 3s 1s forwards;
}

@keyframes flicker {
  0% {
    background-color: rgba(255, 0, 0, 0.5);
  }
  20% {
    background-color: rgba(0, 0, 255, 0.5);
  }
  40% {
    background-color: rgba(255, 0, 0, 0.5);
  }
  60% {
    background-color: rgba(0, 0, 255, 0.5);
  }
  80% {
    background-color: rgba(255, 0, 0, 0.5);
  }
  100% {
    background-color: rgba(0, 0, 255, 0.5);
  }
}

@keyframes fade {
  from {
    background-color: rgba(0, 0, 255, 0.45);
  }
  to {
    background-color: transparent;
  }
}

#response-times-container {
  width: 360px;
}

#call-list-container,
#response-times-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 95%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: sans-serif;
  border-radius: 8px;
  overflow: hidden;
  overscroll-behavior: none;
  pointer-events: none;
  user-select: none;
  touch-action: none;
}

.received-time-ago-hours {
  font-size: 35px;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  border: #292828;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  user-select: none;
  touch-action: none;
  pointer-events: none;
}

#response-times-container h2 {
  max-width: 98%;
  display: inline-block;
  word-wrap: break-word;
}

#call-list-container h2,
#response-times-container h2 {
  position: relative;
  color: white;
  font-family: sans-serif;
  font-size: 18px;
  border: #292828;
  border-width: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  user-select: none;
  line-height: 0.1;
  margin-top: 18px;
  margin-bottom: 18px;
}

#call-list-container h4 {
  font-size: 12px;
  margin-top: 4px;
  font-style: italic;
  margin-bottom: 12px;
  line-height: 0.1;
}

#response-times-container h3 {
  display: inline-block;
  max-width: 98%;
  word-wrap: break-word;
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  line-height: 0;
  font-style: italic;
}

#call-list-container h4,
#response-times-container h3 {
  position: relative;
  color: white;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  font-weight: normal;
  border: #292828;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  user-select: none;
  white-space: nowrap;
}

#call-list {
  display: flex;
  padding: 1px;
  margin: 0px 0px 4px 0px;
  left: 1px;
  width: 94%;
}

#call-list,
#response-times-list {
  position: relative;
  list-style: none;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
  touch-action: pan-y;
  -ms-touch-action: auto;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  overscroll-behavior: none;
  flex-wrap: wrap;
  bottom: 3px;
  white-space: nowrap;
}
#response-times-list {
  top: 7px;
  line-height: 0.07;
  padding: 0px 0px 5px 5px;
  margin: 0px;
  width: 93%;
}

.call-box {
  overflow: hidden;
  background-color: #2f2f2f;
  width: 281px;
  /* ^^ Changed on Feb 15 2024 */
  text-align: start;
  height: 72px;
  font-size: auto;
  color: white;
  border-radius: 8px;
  padding: 2px 5px 3px 1px !important;
  margin: 4px 4px 0px 0px;
  cursor: pointer;
  white-space: nowrap;
}

.recent-resp {
  background-color: rgb(38, 38, 76);
}

.recent-disp {
  background-color: #575757;
}

.call-box-no-merit p,
.call-box-no-merit h3 {
  text-decoration: line-through;
  /* opacity: 0.6; */
}

.call-box:active {
  transform: scale(1.025);
  transform-origin: left;
  background-color: #0e0e0eeb;
}

.call-box h3 {
  font-size: auto;
  margin: 1.5px 7px -2px;
}

.call-box p {
  font-size: 13px;
  color: rgb(255, 255, 255);
  font-family: sans-serif;
  background-color: transparent;
  margin: 0px 9px 0px;
}

#call-list-container {
  width: 83%;
}

@media screen and (min-width: 370px) {
  #call-list-container {
    width: 308px;
  }
}

@media screen and (min-width: 387px) {
  #call-list-container {
    width: 320px;
  }
}

@media screen and (min-width: 700px) {
  #call-list-container {
    width: 628px;
  }
  .call-box {
    width: 280px;
  }
}

@media screen and (min-width: 1010px) {
  #call-list-container {
    width: 937px;
  }
}

@media screen and (min-width: 1334px) {
  #call-list-container {
    width: 1245px;
  }
}

@media screen and (min-width: 1654px) {
  #call-list-container {
    width: 1554px;
  }
}

@media screen and (min-width: 1960px) {
  #call-list-container {
    width: 1862px;
  }
}
/* here */
@media screen and (min-width: 2600px) {
  #call-list-container {
    width: 95.338%;
  }
}

.nearby-list {
  max-width: 340px;
}

/*----RESPONSE TIMES LIST----*/
#response-times-container {
  background: linear-gradient(
    to bottom left,
    rgba(1, 8, 147, 0.8),
    rgba(0, 0, 0, 0.8)
  );
  animation: flicker 1s, fade 3s 1s forwards;
}

/*----SCROLL BAR----*/
#call-list::-webkit-scrollbar,
#response-times-list::-webkit-scrollbar {
  -webkit-appearance: scrollbar;
  width: 10px;
}

#call-list::-webkit-scrollbar-track,
#response-times-list::-webkit-scrollbar-track {
  background-color: #292828;
  border-radius: 10px;
  margin-bottom: 6px;
}

#call-list::-webkit-scrollbar-thumb,
#response-times-list::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to bottom,
    #d53e5c,
    #d53e5c,
    #f46d43,
    #f46d43,
    #f0fe8b,
    #f0fe8b,
    #66c2a5,
    #00af7c,
    #00af7c,
    #3288bd,
    #3288bd,
    #a098f5,
    #a358ea,
    #a358ea,
    #f598ea,
    #888888
  );
  border-radius: 3px;
  border-radius: 3px;
}

@keyframes rainbow {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 100%;
  }
}

::-webkit-scrollbar-thumb {
  animation: rainbow 10s ease infinite;
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

#car-breakins-text {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, +50%);
  width: auto;
  height: auto;
  z-index: 3;
  text-align: center;
  font-size: 26px;
  pointer-events: none;
  user-select: none;
  padding: 4px 9px 6px 9px;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  color: rgba(39, 39, 39, 0.84);
  background-color: rgba(255, 255, 255, 0.8);
  text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
  vertical-align: center;
  white-space: nowrap;
  border-radius: 3px;
  border-radius: 5px;
  border: none;
  transition: transform 0.5s ease;
  touch-action: none;
}

#car-breakins-subtext {
  position: fixed;
  top: 53px;
  left: 50%;
  font-size: 12px;
  z-index: 2;
  text-align: center;
  touch-action: none;
  pointer-events: none;
  user-select: none;
  padding: 4px 7px 6px 7px;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  color: #5b5953;
  background-color: rgba(245, 243, 241, 0.7);
  text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
  vertical-align: center;
  white-space: nowrap;
  border-radius: 5px;
  border: none;
  transition: transform 0.5s ease;
  transform: translate(-50%, +50%);
  touch-action: none;
}

@media screen and (max-width: 450px) {
  #car-breakins-text {
    font-size: 17px;
  }

  #car-breakins-subtext {
    top: 39.4px;
    font-size: 10px;
  }
}

/* ---- Dark Mode ---- */
@media (prefers-color-scheme: dark) {
  #car-breakins-text,
  #car-breakins-subtext {
    background-color: rgba(28, 28, 28, 0.8);
    color: rgba(250, 250, 250, 0.95);
    border: none;
    text-shadow: none;
  }
}
