#project-info-container {
  width: 80%;
  height: 70%;
  z-index: 5;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(to bottom left, #00044fb3, #000000b3);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

#project-info a {
  color: #dc7c06;
}

#project-info a:visited {
  color: #00b70f;
}

#project-info {
  z-index: 5;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  padding: 20px;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  position: fixed;
}

#project-info h2 {
  font-size: 1rem;
  font-style: bold;
  line-height: .5;
}

#project-info p {
  font-size: .95rem;
  line-height: 1.1;
}

@media screen and (width <= 400px) {
  #project-info h2 {
    font-size: .9rem;
    font-style: bold;
    line-height: .5;
  }

  #project-info p {
    font-size: .75rem;
    line-height: 1;
  }
}

@media screen and (width <= 300px) {
  #project-info h2 {
    font-size: .7rem;
    line-height: 1;
  }

  #project-info p {
    font-size: .68rem;
    line-height: 1;
  }
}

@media screen and (width >= 768px) and (height >= 600px) {
  #project-info p {
    padding-left: 20px;
    font-size: 1.3rem;
  }

  #project-info h2 {
    font-size: 2rem;
    font-style: bold;
    padding-left: 20px;
  }
}

#call-list-container {
  background: linear-gradient(to bottom left, #01089380, #00000080);
  animation: 1s flicker, 3s 1s forwards fade;
}

@keyframes flicker {
  0% {
    background-color: #ff000080;
  }

  20% {
    background-color: #0000ff80;
  }

  40% {
    background-color: #ff000080;
  }

  60% {
    background-color: #0000ff80;
  }

  80% {
    background-color: #ff000080;
  }

  100% {
    background-color: #0000ff80;
  }
}

@keyframes fade {
  from {
    background-color: #0000ff73;
  }

  to {
    background-color: #0000;
  }
}

#response-times-container {
  width: 360px;
}

#call-list-container, #response-times-container {
  max-height: 95%;
  z-index: 10;
  color: #fff;
  overscroll-behavior: none;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.received-time-ago-hours {
  text-shadow: 1px 1px 2px #00000080;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  pointer-events: none;
  border: #292828;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  font-size: 35px;
}

#response-times-container h2 {
  max-width: 98%;
  word-wrap: break-word;
  display: inline-block;
}

#call-list-container h2, #response-times-container h2 {
  color: #fff;
  text-shadow: 1px 1px 2px #00000080;
  -webkit-user-select: none;
  user-select: none;
  border: 5px #292828;
  margin-top: 18px;
  margin-bottom: 18px;
  font-family: sans-serif;
  font-size: 18px;
  line-height: .1;
  position: relative;
}

#call-list-container h4 {
  margin-top: 4px;
  margin-bottom: 12px;
  font-size: 12px;
  font-style: italic;
  line-height: .1;
}

#response-times-container h3 {
  max-width: 98%;
  word-wrap: break-word;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 13px;
  font-style: italic;
  line-height: 0;
  display: inline-block;
}

#call-list-container h4, #response-times-container h3 {
  color: #fff;
  text-shadow: 1px 1px 2px #00000080;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  border: #292828;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  font-weight: normal;
  position: relative;
}

#call-list {
  width: 94%;
  margin: 0 0 4px;
  padding: 1px;
  display: flex;
  left: 1px;
}

#call-list, #response-times-list {
  pointer-events: auto;
  touch-action: pan-y;
  -ms-touch-action: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  user-select: none;
  overscroll-behavior: none;
  white-space: nowrap;
  border-radius: 8px;
  flex-wrap: wrap;
  list-style: none;
  position: relative;
  bottom: 3px;
  overflow-x: hidden;
  overflow-y: auto;
}

#response-times-list {
  width: 93%;
  margin: 0;
  padding: 0 0 5px 5px;
  line-height: .07;
  top: 7px;
}

.call-box {
  width: 281px;
  text-align: start;
  height: 72px;
  font-size: auto;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  background-color: #2f2f2f;
  border-radius: 8px;
  margin: 4px 4px 0 0;
  overflow: hidden;
  padding: 2px 5px 3px 1px !important;
}

.recent-resp {
  background-color: #26264c;
}

.recent-disp {
  background-color: #575757;
}

.call-box-no-merit p, .call-box-no-merit h3 {
  text-decoration: line-through;
}

.call-box:active {
  transform-origin: 0;
  background-color: #0e0e0eeb;
  transform: scale(1.025);
}

.call-box h3 {
  font-size: auto;
  margin: 1.5px 7px -2px;
}

.call-box p {
  color: #fff;
  background-color: #0000;
  margin: 0 9px;
  font-family: sans-serif;
  font-size: 13px;
}

#call-list-container {
  width: 83%;
}

@media screen and (width >= 370px) {
  #call-list-container {
    width: 308px;
  }
}

@media screen and (width >= 387px) {
  #call-list-container {
    width: 320px;
  }
}

@media screen and (width >= 700px) {
  #call-list-container {
    width: 628px;
  }

  .call-box {
    width: 280px;
  }
}

@media screen and (width >= 1010px) {
  #call-list-container {
    width: 937px;
  }
}

@media screen and (width >= 1334px) {
  #call-list-container {
    width: 1245px;
  }
}

@media screen and (width >= 1654px) {
  #call-list-container {
    width: 1554px;
  }
}

@media screen and (width >= 1960px) {
  #call-list-container {
    width: 1862px;
  }
}

@media screen and (width >= 2600px) {
  #call-list-container {
    width: 95.338%;
  }
}

.nearby-list {
  max-width: 340px;
}

#response-times-container {
  background: linear-gradient(to bottom left, #010893cc, #000c);
  animation: 1s flicker, 3s 1s forwards fade;
}

#call-list::-webkit-scrollbar, #response-times-list::-webkit-scrollbar {
  -webkit-appearance: scrollbar;
  width: 10px;
}

#call-list::-webkit-scrollbar-track, #response-times-list::-webkit-scrollbar-track {
  background-color: #292828;
  border-radius: 10px;
  margin-bottom: 6px;
}

#call-list::-webkit-scrollbar-thumb, #response-times-list::-webkit-scrollbar-thumb {
  background: linear-gradient(#d53e5c, #d53e5c, #f46d43, #f46d43, #f0fe8b, #f0fe8b, #66c2a5, #00af7c, #00af7c, #3288bd, #3288bd, #a098f5, #a358ea, #a358ea, #f598ea, #888);
  border-radius: 3px;
}

@keyframes rainbow {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 0 100%;
  }
}

::-webkit-scrollbar-thumb {
  animation: 10s infinite rainbow;
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

#car-breakins-text {
  width: auto;
  height: auto;
  z-index: 3;
  text-align: center;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  color: #272727d6;
  text-shadow: 0 1px 2px #fffc;
  vertical-align: center;
  white-space: nowrap;
  touch-action: none;
  background-color: #fffc;
  border: none;
  border-radius: 5px;
  padding: 4px 9px 6px;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  font-size: 26px;
  transition: transform .5s;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  box-shadow: 0 0 6px 1px #00000080;
}

#car-breakins-subtext {
  z-index: 2;
  text-align: center;
  touch-action: none;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  color: #5b5953;
  text-shadow: 0 1px 2px #fffc;
  vertical-align: center;
  white-space: nowrap;
  touch-action: none;
  background-color: #f5f3f1b3;
  border: none;
  border-radius: 5px;
  padding: 4px 7px 6px;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  font-size: 12px;
  transition: transform .5s;
  position: fixed;
  top: 53px;
  left: 50%;
  transform: translate(-50%, 50%);
  box-shadow: 0 0 6px 1px #00000080;
}

@media screen and (width <= 450px) {
  #car-breakins-text {
    font-size: 17px;
  }

  #car-breakins-subtext {
    font-size: 10px;
    top: 39.4px;
  }
}

@media (prefers-color-scheme: dark) {
  #car-breakins-text, #car-breakins-subtext {
    color: #fafafaf2;
    text-shadow: none;
    background-color: #1c1c1ccc;
    border: none;
  }
}

/*# sourceMappingURL=index.1b11e55f.css.map */
